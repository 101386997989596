/**
 *
 * Commercial Sundays and holidays in Poland
 *
 *
 *
 */



// using following Spencer algorithm for calculating Easter date
// source: https://github.com/mk-pmb/easter-date-spencer-pmb-js

function spencerAlgo(gregorianYear) {
    if (gregorianYear < 325) {
        throw new RangeError('Cannot calculate Easter dates before 325 AD.');
    }
    function mod(a, b) { return (a % b); }
    function div(a, b) {
        var q = (a / b);
        if (q < 0) { throw new Error('Unexpected negative q'); }
        return Math.floor(q);
    }
    var y = gregorianYear, skipMarchDays = 21,
        a = mod(y, 19),
        b = div(y, 100),
        c = mod(y, 100),
        d = div(b, 4),
        e = mod(b, 4),
        f = div(b + 8, 25),
        g = div(b - f + 1, 3),
        h = mod((19 * a) + b - d - g + 15, 30),
        i = div(c, 4),
        k = mod(c, 4),
        l = mod(32 + (2 * e) + (2 * i) - h - k, 7),
        m = div(a + (11 * h) + (22 * l), 451),
        t = h + l - (7 * m) + skipMarchDays,
        n = div(t, 31) + 3,
        p = mod(t, 31);
    return { month: n, day: p + 1 };
}

// docs: https://day.js.org/docs/en/installation/installation
//import {isSunday, addDays, setDay} from "date-fns";
const dayjs = useDayjs()
import  weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)
const setDay = (date,day)=>{
    return dayjs(date).weekday(day).toDate()
}
const addDays = (date,days)=>{
    return dayjs(date).add(days,'day').toDate()
}
const isSunday = (date)=>{
    return dayjs(date).day()===0
}


function isPolishCommercialHoliday( date ){
    const holidaysPolish = ["1.1","1.6","5.1","5.3","8.15","11.1","11.11","12.25","12.26"]
    const commercialSundays = []
    const year = date.getFullYear()
    const spencer = spencerAlgo(year)
    const easter = new Date(year, spencer.month - 1, spencer.day)
    function format(date){
        return (date.getMonth()+1)+'.'+date.getDate()
    }
    function getSundayAfter(year,month,date){
        const d = new Date(year,month,date)
        const sunday = setDay( d, 6, {weekStartsOn: 1})
        return isSunday(d) ? addDays(d,7) : sunday
    }
    // moving Polish holidays
    holidaysPolish.push( format(easter) )               // Niedziela Wielkanocna
    holidaysPolish.push( format( addDays(easter,1)) )   // Poniedziałek Wielkanocny
    holidaysPolish.push( format( addDays(easter,49)) )  // Zesłanie Duch Świętego (niedziela)
    holidaysPolish.push( format( addDays(easter,60)) )  // Boże Ciało

    //console.log('holidays',year,holidaysPolish)

    // commercial sundays according to https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20180000305/U/D20180305Lj.pdf
    // Art 7.1 Ustawy o ograniczeniu handlu w niedziele z dnia 10 stycznia 2018r.  Dz. U. 2018 poz. 305
    // Zakaz nie obowiązuje w:
    // 1) kolejne dwie niedziele poprzedzające Wigilię (po zmianie w 2023 z zapisu: pierwszy dzień Bożego Narodzenia);
    // 2) niedzielę bezpośrednio poprzedzającą pierwszy dzień Wielkiej Nocy;
    // 3) ostatnią niedzielę przypadającą w styczniu, kwietniu, czerwcu i sierpniu.
    // 4) poprawione zgodnie z ustawą z grudnia 2023

    commercialSundays.push( format(addDays(getSundayAfter(year,0,31),-7) ))
    commercialSundays.push( format(addDays(getSundayAfter(year,3,30),-7) ))
    commercialSundays.push( format(addDays(getSundayAfter(year,5,30),-7) ))
    commercialSundays.push( format(addDays(getSundayAfter(year,7,31),-7) ))
    commercialSundays.push( format(addDays(easter,-7) ))
    commercialSundays.push( format(addDays(getSundayAfter(year,11,23),-7) ))
    commercialSundays.push( format(addDays(getSundayAfter(year,11,23),-14) ))

    //console.log('commercial sundays',year,commercialSundays)

    const monthDay = (date.getMonth()+1)+'.'+date.getDate()
    if(_includes(holidaysPolish, monthDay)) return true
    if( isSunday(date) ){
        return !_includes(commercialSundays, monthDay)
    } else return false

}

export const useHolidays = ()=>{
    return {
        isHoliday: isPolishCommercialHoliday,
        isSunday,
    }
}